import React from 'react';
import './css/Register.css';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from './Navbar';
import Footer from './Footer';

export default function Register() {
    // const [file, setFile] = useState(null);
    const [inputs, setInputs] = useState({
        username: "",
        phone: "",
        email: "",
        password: "",
    });

    
    const navigate = useNavigate();

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
         try {
            await axios.post("/auth/register", inputs,{
                // img : file ? imgUrl : file,
            });
            navigate("/login");
            alert("User has been created SuccessFully.");
        } catch (err) {
            // setError(err.response.data);
            // console.log(err);
            alert("User already exists!")
        }
    };

    function Focus() {
        var element = document.getElementById("RegisterPage");
        document.getElementById("HomePage").classList.remove("active");
        document.getElementById("TutorialPage").classList.remove("active");
        document.getElementById("AboutPage").classList.remove("active");
        document.getElementById("LoginPage").classList.remove("active");
        element.classList.add("active");
    }

    return (
        <div>
            <>
                <Navbar />
                <div className="container-fluid p-0 registerMainContainer" onLoad={Focus}>

                    <div className="container">
                        <div className="row" id='registerMainBlock'>

                            <div className="col-md-6">
                                <img src={require('../images/signup.png')} alt="director" />
                            </div>

                            <div className="col-md-6">
                                <div className="resisterFormBlock">
                                    <div className="registerInnerBlock">
                                        <h1 className="heading">Register Now</h1>
                                        <hr />
                                        <div className="registerFormBlock">
                                            <form>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputText1" className="form-label">Full Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter your name" name="username" onChange={handleChange} id="exampleInputText1" required />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputText3" className="form-label">Phone number</label>
                                                    <input type="tel" className="form-control" placeholder="0000-000-000" pattern="[0-9]{3}[0-9]{4}[0-9]{3}" name="phone" onChange={handleChange} id="exampleInputText1" required />
                                                </div>

                                                <div className="mb-3">
                                                    <span id="check-email"></span>
                                                    <label htmlFor="exampleInputText2" className="form-label">Email</label>
                                                    <input type="email" className="form-control" placeholder="Enter your Email ID" name="email" onChange={handleChange} id="exampleInputText2" required />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputText3" className="form-label">Password</label>
                                                    <input type="password" className="form-control" placeholder="Enter Passowrd" name="password" onChange={handleChange} id="exampleInputText1" required />
                                                </div>


                                                <button className="btn btn-primary" style={{ fontWeight: '500' }} onClick={handleSubmit}>Sign-up</button>
                                                
                                                
                                            </form>

                                            <div style={{ padding: '10px 0' }}>
                                                <p>Already have an account then Login by <Link to="/login">Click here</Link></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 90">
                    <path fill="#282a35" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                </svg>

                <Footer />
            </>
        </div>
    )
}
