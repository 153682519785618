import './css/AdminDashboard.css';
import Sidebar from './AdminSidebar';
import toggle from './js/toggle.js';
import React from "react";
import axios from "axios";
import { useState, useEffect } from 'react';


export default function AdminDashboard(props) {

    const [inputs, setInputs] = useState({
        subjects: ""
    });

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post("/allsubject/subjects", inputs);
            alert("Add Subject");
        } catch (err) {
            // setError(err.res.data);
            alert("Error")
        }
    };

    const [data, setData] = useState([]);

    const loadData = async () => {
        try {
            const response = await axios.get("/allsubject/alltotal");
            setData(response.data);
            // console.log(response.data);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadData();
    }, []);


    const [dataa, setDataa] = useState([]);

    const loadDataa = async () => {
        try {
            const response = await axios.get("/allsubject/currentstudent");
            setDataa(response.data);
            // console.log(response.data);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadDataa();
    }, []);



    function Hovering() {
        var element = document.getElementById("admin_adminDashboard");
        element.classList.add("hovered");
    }

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="adminMain" onLoad={Hovering}>

                <div className="adminTopbar" onLoad={toggle}>

                    <div className="toggle" onClick={toggle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row adminCardBox">
                    <div className="col-md card">
                        
                        <div>
                        {data.map((item, index) => {
                                    return (
                            
                            <div className="numbers"><div>{item.all_student}</div></div>

                            )}
                            )}
        
                            <div className="cardName">Total Students</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-regular fa-clipboard"></i>
                        </div>
                    </div>
                    <div className="col-md card">
                        <div>
                        {dataa.map((item, index) => {
                                    return (

                         <div className="numbers"><div>{item.CurrentStudent}</div></div>
                            )}
                            )}
        
                            
                            <div className="cardName">Current Students</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-check-double"></i>
                        </div>
                    </div>
                    <div className="col-md card">
                        <div>
                        {data.map((item, index) => {
                                    return (

                         <div className="numbers"><div>&#8377;{item.due}</div></div>
                            )}
                            )}
        
                            <div className="cardName">Total Dues</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-laptop-code"></i>
                        </div>
                    </div>
                    <div className="col-md card">
                        <div>

                        {data.map((item, index) => {
                                    return (

                         <div className="numbers"><div>&#8377;{item.totalIncome}</div></div>
                            )}
                            )}
        

                            
                            <div className="cardName">Total Income</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-indian-rupee-sign"></i>
                        </div>
                    </div>
                </div>

                <div className="row adminSecondBlock">

                    <div className="col-md-8 mb-4 adminSecondBlock2">
                        <div className="innerBlock2">
                            <div className="row">
                                <div className="col">
                                    <h4>Projects</h4>
                                </div>
                                <div className="col">
                                    <button style={{ float: "right", background: "#282a35" }} className='btn btn-sm btn-primary' data-bs-toggle="modal" data-bs-target="#addCourseModal">Add Subject</button>
                                </div>
                                {/* ADD COURSE MODAL */}
                                <div className="modal fade" id="addCourseModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h1 className="modal-title fs-5" id="exampleModalLabel">Add Course</h1>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="form-floating mb-2">
                                                    <input type="text" name="subjects" onChange={handleChange} className="form-control" id="course_name" placeholder="Enter Course Name" />
                                                    <label htmlFor="course_name">Enter Course Name</label>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" onClick={handleSubmit}  className="btn btn-secondary">Add Course</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />
                            <div className="projectsBlock px-2">

                                <div className="row projectHead">
                                    <div className="col"><p className='projectNameHeading'>Project Name</p></div>
                                    <div className="col"><p className='projectStatusHeading'>Status</p></div>
                                </div>

                                <div className="row projectRow">
                                    <div className="col"><p className='projectName'>Chalks and Boards</p></div>
                                    <div className="col"><p className='projectStatus'>Complete</p></div>
                                </div>
                                <div className="row projectRow">
                                    <div className="col"><p className='projectName'>Keshav Mobile</p></div>
                                    <div className="col"><p className='projectStatus'>Complete</p></div>
                                </div>
                                <div className="row projectRow">
                                    <div className="col"><p className='projectName'>Soil Testing Machine</p></div>
                                    <div className="col"><p className='projectStatus'>Complete</p></div>
                                </div>
                                <div className="row projectRow">
                                    <div className="col"><p className='projectName'>Toyjet</p></div>
                                    <div className="col"><p className='projectStatus'>In Progress</p></div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4 adminSecondBlock1">
                        <div className="innerBlock1">
                            <h4>Employees</h4>
                            <hr />
                            <div className="employeesBlock px-2">

                                <div className="row employee my-3">
                                    <div className="col-3 employeeIMG">
                                        <img src={require('../../images/abhishek.jpeg')} alt="employee" />
                                    </div>
                                    <div className="col-9 employeeDetails">
                                        <p>Abhishek Raikwar</p>
                                        <span>Project Manager</span>
                                    </div>
                                </div>

                                <div className="row employee my-3">
                                    <div className="col-3 employeeIMG">
                                        <img src={require('../../images/mahendra.png')} alt="employee" />
                                    </div>
                                    <div className="col-9 employeeDetails">
                                        <p>Mahendra Patankar</p>
                                        <span>HR</span>
                                    </div>
                                </div>

                                <div className="row employee my-3">
                                    <div className="col-3 employeeIMG">
                                        <img src={require('../../images/vijay.png')} alt="employee" />
                                    </div>
                                    <div className="col-9 employeeDetails">
                                        <p>Vijay Dangi</p>
                                        <span>Developer</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
