import React from 'react';
import './css/UploadQuiz.css';
import Sidebar from './AdminSidebar';
import toggle from './js/toggle.js';
import axios from "axios";
import { useState, useEffect } from 'react';


export default function UploadQuiz(props) {

    const [data, setData] = useState([]);

    const loadData = async () => {
        try {
            const response = await axios.get("/allsubject/subjectlist");
            setData(response.data);
            console.log(response.data);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadData();
    }, []);


    const [inputs, setInputs] = useState({
        course: "",
        duration: "",
        q_number: "",
        quiz_number: "",
        questionNo: "",
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        correct_option: "",

    });

    // const [err, setError] = useState(null);

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
           await axios.post("/admin/createtable", inputs);
        
        } catch (err) {
            // setError(err.response.data);
        }

        try {
            await axios.post("/admin/tabledata", inputs);
            alert("Successfully Data Inserted");
         } catch (err) {
            //  setError(err.response.data);
             alert("Error");
         }
 

       
    };


    function Hovering() {
        var element = document.getElementById("admin_uploadTest");
        element.classList.add("hovered");
    }

    function DisplayQ_Block() {
        var element = document.getElementById("QuestionBlock");
        element.style.display = "block";
    }

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="adminMain" onLoad={Hovering}>

                <div className="adminTopbar" onLoad={toggle}>

                    <div className="toggle" onClick={toggle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row uploadQuizDetails">
                    <div className="row uploadQuizDetailBar gx-2">
                        <div className="col-md-6">
                            <div className="form-floating mb-2">
                                <select className="form-select" name="course" onChange={handleChange} id="choose_course">
    
                                    <option hidden></option>
                                    {data.map((item, index) => {
                                    return (

                                    <option key={item.subjects}>{item.subjects}</option>

                                

                                    )}
                                    )}
                
    
                                </select>
                                <label htmlFor="choose_course">Choose Course For Quiz</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating mb-2">
                                <input type="number" name="duration" onChange={handleChange} className="form-control" id="quiz_duration" placeholder="Quiz Duration" />
                                <label htmlFor="quiz_duration">Enter Quiz Duration In Minutes</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-floating mb-2">
                                <input type="number" name="q_number" onChange={handleChange} className="form-control" id="quiz_questions" placeholder="Number Of Questions" />
                                <label htmlFor="quiz_questions">Enter Total Number Of Questions</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-floating mb-2">
                                <input type="number" name="quiz_number" onChange={handleChange} className="form-control" id="Quiz Number" placeholder="Enter Quiz Number" />
                                <label htmlFor="Quiz Number">Enter Quiz Number</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <button style={{ width: "-webkit-fill-available" }} onClick={DisplayQ_Block} className='btn btn-secondary'>Next</button>
                        </div>
                    </div>
                </div>

                <div style={{display: "none"}} id='QuestionBlock' className="row quizQuestionsContainer">

                    <div className="col-md-12 mb-4 quizQuestionBlock">
                        <div className="quizQuestionInnerBlock">
                            <h5>Question Feilds</h5>
                            <hr />

                            <div className="QuestionFeild">
                                <div className="row">
                                    <div className="question_no col-sm-3">
                                        <label htmlFor="Question_no">Question No.</label>
                                        <input type="text"  placeholder="Question No." id="Question_no" name="questionNo"  onChange={handleChange} className="form-control mt-1" required />
                                    </div>
                                    <div className="question col-sm-6">
                                        <label htmlFor="Question">Question</label>
                                        <input type="text" placeholder="Enter Question Here" name="question" id="Question"  onChange={handleChange} className="form-control mt-1" required />
                                    </div>
                                    <div className="question_image col-sm-3">
                                        <label htmlFor="Question_image">Question Image</label>
                                        <input type="file" id="Question_image" name="questionImage"  onChange={handleChange} className="form-control mt-1 mb-4" />
                                    </div>

                                    <div className="option row pe-0">
                                        <div className="col-sm-6">
                                            <label htmlFor="Option1">Option 1</label>
                                            <input type="text" placeholder="Enter Option 1" name="option1"  onChange={handleChange} id="Option1" className="form-control mt-1 mb-2" />
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="Option2">Option 2</label>
                                            <input type="text" placeholder="Enter Option 2" name="option2"  onChange={handleChange} id="Option2" className="form-control mt-1 mb-2" />
                                        </div>
                                    </div>
                                    <div className="option row pe-0">
                                        <div className="col-sm-6">
                                            <label htmlFor="Option3">Option 3</label>
                                            <input type="text" placeholder="Enter Option 3" name="option3"  onChange={handleChange} id="Option3" className="form-control mt-1 mb-2" />
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="Option4">Option 4</label>
                                            <input type="text" placeholder="Enter Option 4" name="option4"  onChange={handleChange} id="Option4" className="form-control mt-1 mb-2" />
                                        </div>
                                    </div>

                                    <div className="questionFoot row pe-0 mt-3">
                                        <div className="col-sm-6">
                                            <label htmlFor="CorrectOption">Correct Option</label>
                                            <input type="text" placeholder="Enter Correct Option" name="correct_option"  onChange={handleChange} id="CorrectOption" className="form-control mt-1 mb-2" />
                                        </div>
                                        <div className="col-sm-6">
                                            <button className='btn btn-secondary mt-3' onClick={handleSubmit}>Upload Quiz</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
