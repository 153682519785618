import React from 'react'
import './css/Home.css';
import img from '../images/index.png';
import service1 from '../images/project_development.jpg';
import service2 from '../images/industrial_training.jpg';
import service3 from '../images/programming_corses.jpg';
import Navbar from './Navbar';
import Footer from './Footer';

export default function Home() {
  // Script for page navigation
  function Focus() {
    var element = document.getElementById("HomePage");
    document.getElementById("AboutPage").classList.remove("active");
    document.getElementById("TutorialPage").classList.remove("active");
    document.getElementById("RegisterPage").classList.remove("active");
    document.getElementById("LoginPage").classList.remove("active");
    element.classList.add("active");
  }
  // Script for services
  function Services1() {
    document.getElementById("serviceImageBlock").style.backgroundImage = `url(${service1})`;
    document.getElementById("serviceNumber").innerHTML = "01";
    document.getElementById("serviceTitle").innerHTML = "Project Development";

    document.getElementById("serviceIcon1").style.display = "block";
    document.getElementById("serviceIcon2").style.display = "none";
    document.getElementById("serviceIcon3").style.display = "none";

    document.getElementById("ServiceHeading").innerHTML = "IITS Provides Project Development Service";
  }
  function Services2() {
    document.getElementById("serviceImageBlock").style.backgroundImage = `url(${service2})`;
    document.getElementById("serviceNumber").innerHTML = "02";
    document.getElementById("serviceTitle").innerHTML = "Industrial Training";

    document.getElementById("serviceIcon1").style.display = "none";
    document.getElementById("serviceIcon2").style.display = "block";
    document.getElementById("serviceIcon3").style.display = "none";

    document.getElementById("ServiceHeading").innerHTML = "IITS Provides Industrial Training To The Students";
  }
  function Services3() {
    document.getElementById("serviceImageBlock").style.backgroundImage = `url(${service3})`;
    document.getElementById("serviceNumber").innerHTML = "03";
    document.getElementById("serviceTitle").innerHTML = "Programming Courses";

    document.getElementById("serviceIcon1").style.display = "none";
    document.getElementById("serviceIcon2").style.display = "none";
    document.getElementById("serviceIcon3").style.display = "block";

    document.getElementById("ServiceHeading").innerHTML = "IITS Teaches Various of Programming Languages";
  }
  return (
    <>
      <Navbar />
      <div className="container-fluid p-0 mainContainer" onLoad={Focus}>

        <div className="container-fluid" id="top_container">
          <div className="container">
            <div className="row topBlock">

              <div className="col-md-8 headLines">
                <h4>Let's Start Coding Now...</h4>
                <h1 className='auto-type'>IITS Code</h1>
                <p>“Learn with IITS CODE and Enhance your programming skills.”</p>
              </div>

              <div className="col-md-4 headImages">
                <center><img src={img} alt="images" /></center>
              </div>

            </div>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 130">
            <path fill="#e8efeb" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
          </svg>

        </div>

        <div className="container-fluid" id='second_container'>
          <div className="container">
            <div className="row secondBlock">

              <div className="col-md-6">

                <div className="codeBlock">
                  <div className="codeBlockHead">
                    <h4 style={{ color: '#ffc107' }}>HTML:</h4>
                  </div>
                  <div className="codeBlockBody firstCodeBlock">
                    &lt;!DOCTYPE html&gt;<br />
                    &lt;html&gt;<br />
                    &lt;title&gt;HTML&lt;/title&gt;<br />
                    &lt;body&gt;<br /><br />
                    &lt;h1&gt;This is a heading&lt;/h1&gt;<br />
                    &lt;p&gt;This is a paragraph.&lt;/p&gt;<br /><br />
                    &lt;/body&gt;<br />
                    &lt;/html&gt;
                  </div>
                </div>

                <div className="titleBlock">
                  <center>
                    <h3 style={{ color: '#ffc107' }}>HTML</h3>
                    <p>HTML Language for Building Web Pages</p>
                    <button className='btn btn-warning btn-sm'>Learn HTML</button>
                  </center>
                </div>

              </div>

              <div className="col-md-6">

                <div className="codeBlock">
                  <div className="codeBlockHead">
                    <h4 style={{ color: '#198754' }}>CSS:</h4>
                  </div>
                  <div className="codeBlockBody secondCodeBlock">
                    body &#x7B;
                    <br />&nbsp;
                    background-color: skyblue;
                    <br />
                    &#x7D;

                    <br /><br />h1 &#x7B;
                    <br />&nbsp;
                    color: white;
                    <br />&nbsp;
                    text-align: center;
                    <br />
                    &#x7D;

                    <br /><br />p &#x7B;
                    <br />&nbsp;
                    font-family: verdana;
                    <br />
                    &#x7D;
                  </div>
                </div>

                <div className="titleBlock">
                  <center>
                    <h3 style={{ color: '#198754' }}>CSS</h3>
                    <p>CSS Language for Styling Web Pages</p>
                    <button className='btn btn-success btn-sm'>Learn CSS</button>
                  </center>
                </div>

              </div>

            </div>

            <div className="row secondBlock">

              <div className="col-md-6">

                <div className="codeBlock">
                  <div className="codeBlockHead">
                    <h4 style={{ color: '#0d6efd' }}>JavaScript:</h4>
                  </div>
                  <div className="codeBlockBody thirdCodeBlock">
                    &lt;button onclick=&quot;myFunction()&quot;&gt;Click Me!&lt;/button&gt;<br /><br />
                    &lt;script&gt;<br />
                    function myFunction() &#x7B;<br />
                    &nbsp; let x = document.getElementById(&quot;demo&quot;);<br />
                    &nbsp; x.style.fontSize = &quot;25px&quot;; <br />
                    &nbsp; x.style.color = &quot;red&quot;; <br />&#x7D;<br />
                    &lt;/script&gt;
                  </div>
                </div>

                <div className="titleBlock">
                  <center>
                    <h3 style={{ color: '#0d6efd' }}>JavaScript</h3>
                    <p>JavaScript Language for Programming Web Pages</p>
                    <button className='btn btn-primary btn-sm'>Learn JavaScript</button>
                  </center>
                </div>

              </div>

              <div className="col-md-6">

                <div className="codeBlock">
                  <div className="codeBlockHead">
                    <h4 style={{ color: '#dc3545' }}>PHP:</h4>
                  </div>
                  <div className="codeBlockBody forthCodeBlock">
                    &lt;!DOCTYPE html&gt;<br />
                    &lt;html&gt;<br />
                    &lt;body&gt;<br /><br />
                    <span className="marked">&lt;?php<br />
                      echo &quot;My first PHP script!&quot;;<br />
                      ?&gt;</span><br />
                    <br />
                    &lt;/body&gt;<br />
                    &lt;/html&gt;
                  </div>
                </div>

                <div className="titleBlock">
                  <center>
                    <h3 style={{ color: '#dc3545' }}>PHP</h3>
                    <p>Server Programming Language For Web Page</p>
                    <button className='btn btn-danger btn-sm'>Learn PHP</button>
                  </center>
                </div>

              </div>

            </div>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 130">
            <path fill="#282a35" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
          </svg>

        </div>

        <div className="container-fluid p-0" id='third_container'>
          <div className="container">
            <div className="row thirdBlock">

              <div className="col-md roadMap">
                <div className="steps firstStep">
                  <div className="step">
                    <h1>01</h1>
                    <span>Join IITSCODE</span>
                  </div>
                  <center><hr /></center>
                  <div className="stepDetails">
                    <i className="fa-regular fa-handshake"></i>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                  </div>
                </div>
              </div>

              <div className="col-md roadMap">
                <div className="steps secondStep">
                  <div className="step">
                    <h1>02</h1>
                    <span>Learn With IITSCODE</span>
                  </div>
                  <center><hr /></center>
                  <div className="stepDetails">
                    <i className="fa-solid fa-laptop-code"></i>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                  </div>
                </div>
              </div>

              <div className="col-md roadMap">
                <div className="steps thirdStep">
                  <div className="step">
                    <h1>03</h1>
                    <span>Give IITSCODE Test</span>
                  </div>
                  <center><hr /></center>
                  <div className="stepDetails">
                    <i className="fa-regular fa-paste"></i>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                  </div>
                </div>
              </div>

              <div className="col-md roadMap">
                <div className="steps forthStep">
                  <div className="step">
                    <h1>04</h1>
                    <span>Get IITSCODE Certificate</span>
                  </div>
                  <center><hr /></center>
                  <div className="stepDetails">
                    <i className="fa-regular fa-address-card"></i>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 130">
            <path fill="#e8efeb" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
          </svg>

        </div>

        <div className="container-fluid p-0" id='forth_container'>
          <div className="container">
            <h4>OUR SERVICES</h4>
            <div className="row forthBlock">

              <div className="col-3 service" onClick={Services1}>
                <div className="firstService">
                  <i className="fa-solid fa-laptop-code"></i>
                  <p>Project Development</p>
                </div>
              </div>

              <div className="col-3 service" onClick={Services2}>
                <div className="secondService">
                  <i className="fa-solid fa-person-chalkboard"></i>
                  <p>Industrial Training</p>
                </div>
              </div>

              <div className="col-3 service" onClick={Services3}>
                <div className="thirdService">
                  <i className="fa-solid fa-code"></i>
                  <p>Programming Courses</p>
                </div>
              </div>

            </div>
          </div>
          <div className="container-fluid">

            <div className="row serviceDetails py-3">

              <div className="col-md serviceImageBlock my-1" id='serviceImageBlock' style={{ backgroundImage: `url(${service1})` }}>
                <div className="serviceNumber">
                  <h1 id='serviceNumber'>01</h1>
                  <p id='serviceTitle'>Project Development</p>
                </div>
                <div className="overlay"></div>
              </div>

              <div className="col-md serviceDetailBlock my-1">
                <div className="DetailInnerBlock">
                  <div className="serviceHead">
                    <i id='serviceIcon1' className="fa-solid fa-laptop-code"></i>
                    <i id='serviceIcon2' style={{ display: "none" }} className="fa-solid fa-person-chalkboard"></i>
                    <i id='serviceIcon3' style={{ display: "none" }} className="fa-solid fa-code"></i>
                  </div>
                  <div className="serviceBody">
                    <h3 id='ServiceHeading'>IITS Provides Project Development Service</h3>
                    {/* <p id='ServiceParagraph'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p> */}
                  </div>
                  <div className="serviceFoot">
                    <button className='btn btn-dark btn-sm'>More <i className="fa-solid fa-right-long"></i></button>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 90">
            <path fill="#282a35" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
          </svg>

        </div>

      </div>
      <Footer/>
    </>
  )

}