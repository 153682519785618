import React, { Fragment } from 'react';
import { PDFViewer } from '@react-pdf/renderer'
import InvoicePage from './InvoicePage';
import { useParams } from 'react-router-dom';


export default function Invoice() {

    const { id } = useParams("");
   
    return (
        
        <Fragment >
            <PDFViewer width="1000" height="600">
                <InvoicePage id={id}/>
            </PDFViewer>
        </Fragment>
        
 )}
