import React from 'react';
import './css/EnrollStudents.css';
import Sidebar from './AdminSidebar';
import toggle from './js/toggle.js';
import { useState, useEffect } from 'react';
import axios from "axios";

export default function EnrollStudents(props) {

const [data, setData] = useState([]);

    const loadData = async () => {
        try {
            const response = await axios.get("/allsubject/subjectlistt");
            setData(response.data);
            console.log(response.data);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadData();
    }, []);


    const [inputs, setInputs] = useState({
        student_name: "",
        contact_number: "",
        student_email: "",
        student_institute: "",
        current_course: "",
        total_fees: "",
        payment: "",
        payment_due: "",
        reference: "",
        password: "",
    });


    // const [err, setError] = useState(null);


    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(inputs);

        try {

            await axios.post("/enrollStudents/register", inputs);
            alert("Registration SuccessFully.");
        } catch (err) {
            // setError(err.res.data);
            alert("User already exists!")
        }
    };

    function Hovering() {
        var element = document.getElementById("admin_enrollStudent");
        element.classList.add("hovered");
    }

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="adminMain" onLoad={Hovering}>

                <div className="adminTopbar" onLoad={toggle}>

                    <div className="toggle" onClick={toggle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row enrollStudentContainer">
                    <div className="enrollStudentBlock">
                        <div className="enrollStudentFormHead">
                            <h4>Enroll New Student</h4>
                            <hr />
                        </div>
                        <div className="enrollStudentFormBody">

                            <form>
                                <div className="row enrollStudentForm">

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <input type="text" name="student_name" onChange={handleChange} className="form-control" id="name" placeholder="Enter Name" />
                                            <label htmlFor="name">Enter Student Name</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <input type="number" name="contact_number" onChange={handleChange} className="form-control" id="contact_number" placeholder="Enter Contact Number" />
                                            <label htmlFor="contact_number">Enter Contact Number</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <input type="email" name="student_email" onChange={handleChange} className="form-control" id="student_email" placeholder="Enter Student Email" />
                                            <label htmlFor="student_email">Enter Student Email</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <input type="text" name="student_institute" onChange={handleChange} className="form-control" id="student_institute" placeholder="Enter Collage/Institute" />
                                            <label htmlFor="student_institute">Enter Collage/Institute</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <select className="form-select" name="current_course" onChange={handleChange} id="select_course">
                                                <option hidden></option>
                                                {data.map((item, index) => {
                                                    return (

                                                        <option key={item.subjects}>{item.subjects}</option>
                                                    )}
                                                )}

                                            </select>
                                            <label htmlFor="select_course">Select Course</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <input type="number" name="total_fees" onChange={handleChange} className="form-control" id="total_fees" placeholder="Enter Total Fees" />
                                            <label htmlFor="total_fees">Enter Total Fees</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <input type="number" name="payment" onChange={handleChange} className="form-control" id="payment" placeholder="Enter Pay Ammount" />
                                            <label htmlFor="payment">Enter Pay Ammount</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <input type="number" name="payment_due" onChange={handleChange} className="form-control" id="payment_due" placeholder="Payment Due" />
                                            <label htmlFor="payment_due">Payment Due</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <input type="text" name="reference" onChange={handleChange} className="form-control" id="reference" placeholder="Reference/Recommended By" />
                                            <label htmlFor="reference">Reference/Recommended By</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentFeild">
                                        <div className="form-floating mb-2">
                                            <input type="password" name="password" onChange={handleChange} className="form-control" id="password" placeholder="Create Password" />
                                            <label htmlFor="password">Create Password</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 enrollStudentButton mt-2">
                                        <button className='btn btn-secondary' onClick={handleSubmit} style={{ width: "-webkit-fill-available" }}>REGISTER</button>

                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
