import './css/Dashboard.css';
import Sidebar from './Sidebar';
import toggling from './js/toggle.js';
import React, { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { useEffect, useState } from 'react';
import axios from "axios";

export default function Dashboard(props) {

    const { currentUser } = useContext(AuthContext);

    function Hovering() {
        var element = document.getElementById("user_dashboard");
        document.getElementById("user_test").classList.remove("hovered");
        element.classList.add("hovered");
    }

    let id = currentUser?.id;
    const [user, setUser] = useState([]);

    const getdata = async () => {

        const res = await fetch(`/enrollStudents/userData/${id}`);
        const data = await res.json();
        // console.log(data);

        if (res.status === 422 || !data) {
            // console.log("error");
        }
        else {
            setUser(data[0])
            // console.log("get data");
        }
    }
    useEffect(() => {
        getdata();
    });

    var allcourses = currentUser?.all_courses;
    var all = allcourses.split(",").length;
    var countt = all - 1;

 // Image Upload code start

    const [file, setFile] = useState(null);
    const uploadImage = async () => {
        try {
            const formData = new FormData();
            formData.append("file", file)
            const res = await axios.put(`/auth/upload/${id}`, formData)
            console.log(res.data);
            alert("File is Uploaded");

        } catch (err) {
            console.log(err);
            alert("file not upload")
        }
    }
   
    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="main" onLoad={Hovering}>

                <div className="topbar" onLoad={toggling}>

                    <div className="toggle" onClick={toggling}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require(`../../upload/${currentUser?.image}`)} alt="userimage" />
                    </div>

                </div>

                <div className="row cardBox">
                    <div className="col-md card">
                        <div>
                            <div className="numbers">12</div>
                            <div className="cardName">Total Test Attempt</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-regular fa-clipboard"></i>
                        </div>
                    </div>
                    <div className="col-md card">
                        <div>

                            <div className="numbers">{countt}</div>
                            <div className="cardName">Completed Course</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-check-double"></i>
                        </div>
                    </div>
                    <div className="col-md card">
                        <div>
                            <div className="numbers">{user.current_course}</div>
                            <div className="cardName">Running Course</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-laptop-code"></i>
                        </div>
                    </div>
                    <div className="col-md card">
                        <div>
                            <div className="numbers">&#8377;{user.payment_due}</div>
                            <div className="cardName">Your Total Due</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-indian-rupee-sign"></i>
                        </div>
                    </div>
                </div>

                <div className="row dashboardSecondBlock">

                    <div className="col-md-4 mb-4 dashboardProfileBlock">
                        <div className="innerProfileBlock">
                            <h4>Profile</h4>
                            <hr />
                            <div className="profileDetails">
                                <center><img src={require(`../../upload/${currentUser?.image}`)} alt="userImage" data-bs-toggle="modal" data-bs-target="#uploadImage" style={{ cursor: "pointer" }} /></center>
                                {/* <center><img src={require(`../../upload/${user.image}`)} alt="userImage" data-bs-toggle="modal" data-bs-target="#uploadImage" style={{ cursor: "pointer" }} /></center> */}
                              

                                {/* Modal */}
                                <div className="modal fade" id="uploadImage" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h1 className="modal-title fs-5" id="exampleModalLabel">Upload Image</h1>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <form>
                                                <div className="modal-body">
                                                    <label htmlFor="image">Upload Image</label>
                                                    <input type="file" className="form-control" id="image" onChange={(e) => setFile(e.target.files[0])} />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-primary" onClick={uploadImage} data-bs-dismiss="modal">OK</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="profileInformationBlock px-3">
                                <div className="row">
                                    <p>Information</p>
                                </div>
                                <div className="row">
                                    <div className="col"><span>Name:</span></div>
                                    <div className='col'><span>{currentUser?.student_name}</span></div>
                                    <hr />
                                </div>
                                <div className="row">
                                    <div className="col"><span>Contact:</span></div>
                                    <div className='col'><span>{currentUser?.contact_number}</span></div>
                                    <hr />
                                </div>
                                <div className="row">
                                    <div className="col"><span>Email:</span></div>
                                    <div className='col'><span>{currentUser?.student_email}</span></div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8 mb-4 dashboardExamBlock">
                        <div className="innerExamBlock">
                            <h4>Previos Test Result</h4>
                            <hr />
                            <div className="examInformationBlock px-3">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col"><span>Course Name:</span></div>
                                            <div className='col'><span>Java</span></div>
                                            <hr />
                                        </div>
                                        <div className="row">
                                            <div className="col"><span>Test No.:</span></div>
                                            <div className='col'><span>5</span></div>
                                            <hr />
                                        </div>
                                        <div className="row">
                                            <div className="col"><span>Test Date:</span></div>
                                            <div className='col'><span>22-02-2023</span></div>
                                            <hr />
                                        </div>
                                        <div className="row">
                                            <div className="col"><span>Test Duration:</span></div>
                                            <div className='col'><span>30 Minutes</span></div>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 box">
                                        <div className="percent">
                                            <svg>
                                                <circle cx="70" cy="70" r="50"></circle>
                                                <circle className='timeCircle' cx="70" cy="70" r="50"></circle>
                                            </svg>
                                            <div className="number">
                                                <h2>25<span>Mins</span></h2>
                                            </div>
                                        </div>
                                        <span className='text'>Test Submition Time</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col box">
                                        <div className="percent">
                                            <svg>
                                                <circle cx="70" cy="70" r="50"></circle>
                                                <circle className='totalQuestionCircle' cx="70" cy="70" r="50"></circle>
                                            </svg>
                                            <div className="number">
                                                <h2>10</h2>
                                            </div>
                                        </div>
                                        <span className='text'>Total Questions</span>
                                    </div>
                                    <div className="col box">
                                        <div className="percent">
                                            <svg>
                                                <circle cx="70" cy="70" r="50"></circle>
                                                <circle className='correctAnswerCircle' cx="70" cy="70" r="50"></circle>
                                            </svg>
                                            <div className="number">
                                                <h2>7</h2>
                                            </div>
                                        </div>
                                        <span className='text'>Correct Answers</span>
                                    </div>
                                    <div className="col box">
                                        <div className="percent">
                                            <svg>
                                                <circle cx="70" cy="70" r="50"></circle>
                                                <circle className='wrongAnswerCircle' cx="70" cy="70" r="50"></circle>
                                            </svg>
                                            <div className="number">
                                                <h2>3</h2>
                                            </div>
                                        </div>
                                        <span className='text'>Wrong Answers</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
