import React from 'react'
import './css/StudentUnlockQuizList.css';
import Sidebar from './AdminSidebar';
import toggle from './js/toggle.js';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Link , useNavigate } from "react-router-dom";

export default function StudentUnlockQuizList(props) {

    const { contact_number } = useParams("");
    const { quiz_number } = useParams("");
    const { course }  = useParams("");

    const navigate = useNavigate();

    function Hovering() {
        var element = document.getElementById("admin_unlockTest");
        element.classList.add("hovered");
    }

    const handleSubmit = async () => {

            try {
             await axios.post(`/quizlist/studentunlock/${contact_number}/${quiz_number}/${course}`);
             alert("Data Add SuccessFully");
             navigate(`/admin/StudentUnlockQuizList/${course}/${quiz_number}`);

            }
        catch (err) {
            
        }
    }

  return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="adminMain" onLoad={Hovering}>

                <div className="adminTopbar" onLoad={toggle}>

                    <div className="toggle" onClick={toggle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row StudentUnlockQuizList">
                    <div className="StudentList">
                        <h5>You realy wants to unlock?</h5>
                         <button className='btn btn-sm btn-warning' onClick={handleSubmit}><i className="fa-solid fa-unlock-keyhole"></i> Add </button>
                         <Link to={`/admin/StudentUnlockQuizList/${course}/${quiz_number}`}><button className='btn btn-sm btn-secondary mx-2'><i className="fa-solid fa-unlock-keyhole"></i> Go back</button></Link>

                    </div>
                </div>

            </div>
        </div>
    )
}
