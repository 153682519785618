import React, { useContext, useEffect, useState } from 'react'
import './css/TestHistory.css';
import Sidebar from './Sidebar';
import toggling from './js/toggle.js';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/authContext';

export default function TestHistory(props) {

    const { currentUser } = useContext(AuthContext);
    let student_email = currentUser?.student_email;

    function Hovering() {
        var element = document.getElementById("user_test_history");
        element.classList.add("hovered");
    }

    function SelectCourse() {
        var selected_course = document.getElementById("select_test_course").value;
        document.getElementById("Selected_Course").innerHTML = selected_course;
    }

    const [data, setData] = useState([]);
    const [dataa, setDataa] = useState([]);

    const loadData = async () => {
        try {
            const response = await axios.get(`/test/completeCourseList/${student_email}`);
            setData(response.data);
        }
        catch (err) {
            // console.log(err);
        }
        try {
            const resp = await axios.get(`/test/totalattempttest/${student_email}`);
            setDataa(resp.data);
        }
        catch (err) {
            // console.log(err);
        }
    };

    const [tests, setTests] = useState([]);
    const [allTest, setAllTest] = useState([]);

    var selected_course;
    const courseSubmit = async () => {
        // e.preventDefault();
        selected_course = document.getElementById("select_test_course").value;

        try {
            const response = await axios.get(`/test/selectedCourseTests/${selected_course}/${student_email}`);
            setTests(response.data);
        }
        catch (err) {
            // console.log(err);
        }

        try {
            const res = await axios.get(`/test/allTest/${selected_course}/${student_email}`);
            setAllTest(res.data);
        }
        catch (err) {
            // console.log(err);
        }
    };

    useEffect(() => {
        loadData();
        courseSubmit();
    }, []);
    
    useEffect(()=>{
        SelectCourse();
    })
    
    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="main" onLoad={Hovering}>

                <div className="topbar" onLoad={toggling}>

                    <div className="toggle" onClick={toggling}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row cardBox">
                    <div className="col-md card">
                        <div>
                            <div className="numbers">{dataa}</div>
                            <div className="cardName">Total Test Attempt</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-clipboard-check"></i>
                        </div>
                    </div>
                    <div className="col-md card">
                        <div>
                            <div className="numbers">{tests}</div>
                            <div className="cardName">Selected Course Test</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-circle-check"></i>
                        </div>
                    </div>
                    <div className="col-md card" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <div>
                            <div className="numbers" id='Selected_Course'></div>
                            <div className="cardName">Selected Course</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-laptop-code"></i>
                        </div>
                    </div>

                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Select Course</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-floating mb-2">
                                        <select className="form-select" name="course" id="select_test_course" onChange={SelectCourse}>
                                            <option hidden>{data[0]}</option>
                                            {data.map((item, index) => {
                                                return (
                                                    <option key={item}>{item}</option>
                                                )
                                            }
                                            )}

                                        </select>
                                        <label htmlFor="select_test_course">Select Course</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" name='select_course' data-bs-dismiss="modal" onClick={courseSubmit}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md card">
                        <div>
                            <div className="numbers">#24</div>
                            <div className="cardName">Your Rank</div>
                        </div>
                        <div className="iconBx">
                            <i className="fa-solid fa-ranking-star"></i>
                        </div>
                    </div>
                </div>

                <div className="row testHistoryContainer">

                    <div className="col-md-12 mb-4 testHistoryBlock">
                        <div className="testHistoryTable">
                            <h4>Attempted Test List</h4>
                            <hr />
                            <div className="historyTable">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Test No.</th>
                                            <th scope="col">Course</th>
                                            <th scope="col">Test Date</th>
                                            <th scope="col">Duration</th>
                                            <th scope="col">Marks</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">

                                        {allTest.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{item.test_number}</th>
                                                    <td>{item.course_name}</td>
                                                    <td>{(new Date(item.test_date)).toLocaleDateString()}</td>
                                                    <td>{item.duration} Mins</td>
                                                    <td>{item.t_question}/{item.total_marks}</td>
                                                    <td><Link to={`/users/testDetails/${item.test_number}/${item.course_name}`}><button className='btn btn-sm btn-secondary'><i className="fa-solid fa-eye"></i> View </button></Link></td>
                                                </tr>
                                            )
                                        }
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

