import React from 'react';
import { useEffect, useState } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1.5, 
    borderRightWidth: 0, 
    borderBottomWidth: 0, 
    marginTop:18,
    color: 'rgba(48,50,127,255)',
    borderColor: 'rgba(48,50,127,255)',
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "50%", 
    borderStyle: "solid",
    borderColor: 'rgba(48,50,127,255)', 
    borderWidth: 1.5, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCell: { 
    marginLeft: 15, 
    marginTop: 5, 
    fontSize: 10,
    fontWeight: 'bold',   
  },
  serial: {
    flexDirection:'row',
    justifyContent: 'center',
    color:'rgba(48,50,127,255)'
  }
});

export default function InvoiceTableHeader({id}) {

  const [user, setUser] = useState([]);
  
  const getdata = async () => {
      const res = await fetch(`/enrollStudents/userdetails/${id}`);
      const data = await res.json();
      // console.log(data);

      if (res.status === 422 || !data) {
          // console.log("error");
      }
      else {
          setUser(data[0])
          // console.log("get data");
      }
  }

  useEffect(() => {
      getdata();
  }, []);


  return (

    <View>
    <View style={styles.serial}>
<Text style={styles.seria} >Serial No. - {user.serial_no}</Text>
</View>

    
      <View style={styles.table}>

        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Name</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{user.student_name}</Text> 
          </View>   
        </View>

        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Date of Registration</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{(new Date(user.AddedOnDate)).toLocaleDateString()} </Text> 
          </View> 
         </View>

         <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Mobile No.</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{user.contact_number}</Text> 
          </View>   
        </View>

        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Email-ID</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{user.student_email}</Text> 
          </View>   
        </View>

        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Training/Project/Course</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{user.current_course}</Text> 
          </View>   
        </View>

        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Institute/Collage</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{user.student_institute}</Text> 
          </View>   
        </View>

        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Fees Details</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{user.total_fees}</Text> 
          </View>   
        </View>

        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Payment</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{user.payment}</Text> 
          </View>   
        </View>

        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Due</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{user.payment_due}</Text> 
          </View>   
        </View>

        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Reference/Recommended</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{user.reference}</Text> 
          </View>   
        </View>

      </View>
      </View>
    
  )
}
