// Menu Toggle
function toggle() {
    let toggle = document.querySelector('.toggle');
    let navigation = document.querySelector('.adminNavigation');
    let main = document.querySelector('.adminMain');

    toggle.onclick = function () {
        navigation.classList.toggle('active')
        main.classList.toggle('active')
    }
}

export default toggle;