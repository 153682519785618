import React, { useContext, useEffect, useState } from 'react';
import './css/TestList.css';
import Sidebar from './Sidebar';
import toggling from './js/toggle.js';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/authContext';

export default function CourseList(props) {

    
    function Hovering() {
        var element = document.getElementById("user_test");
        document.getElementById("user_dashboard").classList.remove("hovered");
        element.classList.add("hovered");
    }

    const { currentUser } = useContext(AuthContext);
    const id = currentUser?.id;

    const [data, setData] = useState([]);

    const loadCourseList = async () => {
        try {
            const res = await axios.get(`/test/courselist/${id}`);
            setData(res.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        loadCourseList();
    });

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="main" onLoad={Hovering}>

                <div className="topbar" onLoad={toggling}>

                    <div className="toggle" onClick={toggling}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row cardBox">

                    {data.map((item, index) => {
                        return (
                            <div className="col-md-3 p-0" key={item}>
                                <Link to={`/users/test_list/${item}`} style={{ textDecoration: "none" }}>
                                    <div className="card">
                                        <div className="numbers">
                                            <p style={{ marginTop: "18px", fontSize: "1.8rem" }}>{item}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}

                </div>

            </div>
        </div>
    )
}
