import React from 'react';
import './css/Test.css';
import Sidebar from './Sidebar';
import toggling from './js/toggle.js';
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

export default function Test(props) {

    const navigate = useNavigate();

    const { currentUser } = useContext(AuthContext);
    let student_id = currentUser?.id;
    let student_name = currentUser?.student_name;
    let student_email = currentUser?.student_email;

    const { course } = useParams("");
    const { test_no } = useParams("");
    const { duration } = useParams("");
    const minn = duration;

    let course_name = course;
    let test_number = test_no;

    const [option, setOption] = useState({});
    const [test, setTest] = useState({
        student_id: student_id,
        student_name: student_name,
        student_email: student_email,
        course_name: course_name,
        test_number: test_number,
        op: [],
    });

    const handleChange = (e) => {
        setOption((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        handleChange2();
    };

    function handleChange2() {
        setTest((prev) => ({
            ...prev,
            op: option
        }))
    }

    const handleSubmitResult = async (e) => {
        e.preventDefault();
        // console.log(test);
        try {
            await axios.post("/test/testresult", test, {
            });
            localStorage.removeItem("timer");
            navigate("/users/dashboard");
        } catch (err) {
            console.log(err);
        }

        try {
           await axios.get(`/test/totaltest/${student_email}`);
        }
        catch (err) {
        //  console.log(err)s
        }
    };

    const [data, setData] = useState([]);
    const [dataa, setDataa] = useState([]);
    const [data3, setDataa3] = useState([]);

    const QuestionBtnList = () => {
        axios.get(`/test/questionbtnlist/${course}/${test_no}`).then(resp => {
            setData(resp.data);
        });
        axios.get(`/test/testentry/${course}/${test_no}`).then(resp => {
            setDataa(resp.data);
        });
        axios.get(`/test/showquestionlist/${course}/${test_no}`).then(resp => {
            setDataa3(resp.data);
        });
    }
    useEffect(() => {
        QuestionBtnList();
    }, []);

    function Hovering() {
        var element = document.getElementById("user_test");
        document.getElementById("user_dashboard").classList.remove("hovered");
        element.classList.add("hovered");
    }

    // Timer Start

    const initialTimer = localStorage.getItem("timer") ?? minn * 60;
    const timeoutId = React.useRef(null);
    const [timer, setTimer] = React.useState(initialTimer);

    // var h = Math.floor(timer / 3600);
    var m = Math.floor(timer % 3600 / 60);
    var s = Math.floor(timer % 3600 % 60);

    var minutes = m < 10 ? "0" + m : m;
    var seconds = s < 10 ? "0" + s : s;

    const countTimer = React.useCallback(() => {
        if (timer <= 0) {
            localStorage.removeItem("timer");
            window.clearTimeout(timeoutId.current)
            alert("Time Up");
            document.querySelector('[name="test"]').click();
        } else {
            setTimer(timer - 1);
            localStorage.setItem("timer", timer);
        }
        if (timer <= 1) {
            document.querySelector('[name="submit"]').click();
        }

    }, [timer]);

    React.useEffect(() => {
        timeoutId.current = window.setTimeout(countTimer, 1000);
        return () => window.clearTimeout(timeoutId.current);
    }, [timer, countTimer]);

    return (
        <div className="container-fluid gx-0">

            <Sidebar />
            <div className="main" onLoad={Hovering}>

                <div className="topbar" onLoad={toggling}>

                    <div className="toggle" onClick={toggling}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row testSecondBlock">
                    <div className="col-md-8 mb-4 quizBlock">
                        <form>

                            {data3.map((item, index) => {
                                return (
                                    <div className="innerQuizBlock my-3" key={index}>

                                        <div className="questionBlock mb-4">
                                            <p><span>Q.{item.questionNo} </span>{item.question}</p>
                                            <hr />
                                        </div>

                                        <div className="row optionsBlock"  >
                                            <div className="col-md-12 my-2 option1">
                                                <label><input type="radio" name={`${index}`} onChange={handleChange} value={item.option1} /> {item.option1}</label>
                                            </div>
                                            <div className="col-md-12 my-2 option2">
                                                <label><input type="radio" name={`${index}`} onChange={handleChange} value={item.option2} /> {item.option2}</label>
                                            </div>
                                            <div className="col-md-12 my-2 option3">
                                                <label><input type="radio" name={`${index}`} onChange={handleChange} value={item.option3} /> {item.option3}</label>
                                            </div>
                                            <div className="col-md-12 my-2 option4">
                                                <label><input type="radio" name={`${index}`} onChange={handleChange} value={item.option4} /> {item.option4}</label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </form>
                    </div>

                    <div className="col-md-4 mb-4 questionsBlock">
                        <div className="innerAllQuestionsBlock my-3">
                            <div className="quizTimeBlock">
                                <p>Quiz Duration: </p>
                                <p>{dataa[1]}:00 <span>Mins</span></p>
                                <hr />
                                <p>CountDown :- {minutes}:{seconds}</p>
                            </div>
                            <hr />
                            <div className="quizQuestionsBlock">
                                <h4>Questions: {dataa[0]}<span></span></h4>
                                <hr />
                                <div className="row">

                                    {data.map((item, index) => {
                                        return (
                                            <div className="col-3 my-2" key={index}>
                                                <button type="button" className="btn btn-primary mx-1">{item.questionNo}</button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="row quizQuestionsBlock_footer mt-2 px-2">
                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#confirmBox" onClick={handleChange} name="submit">Submit Test <i className="fa-sharp fa-solid fa-paper-plane"></i></button>
                                </div>
                            </div>
                        </div>
                        {/* CONFIRMATION MODAL */}
                        <div className="modal fade" id="confirmBox" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Are you sure you want to submit</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <center><button type="button" className="btn btn-lg btn-secondary mx-1" data-bs-dismiss="modal">Cancel <i className="fa-solid fa-xmark"></i></button>
                                             <button type="button" name='test' id='test' onClick={handleSubmitResult} className="btn btn-lg btn-primary mx-1">Submit <i className="fa-sharp fa-solid fa-paper-plane"></i></button></center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}


