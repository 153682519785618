import React, { useEffect, useState } from 'react';
import './css/TestEntry.css';
import Sidebar from './Sidebar';
import toggling from './js/toggle.js';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

export default function TestEntry(props) {
    function Hovering() {
        var element = document.getElementById("user_test");
        document.getElementById("user_dashboard").classList.remove("hovered");
        element.classList.add("hovered");
    }

    const { course } = useParams("");
    const { test_no } = useParams("");

    const [data, setData] = useState([]);

    const loadEntryDetails = async () => {
        try {
            const res = await axios.get(`/test/testentry/${course}/${test_no}`);
            setData(res.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        loadEntryDetails();
    });

    const duration = data[1];

   
    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="main" onLoad={Hovering}>

                <div className="topbar" onLoad={toggling}>

                    <div className="toggle" onClick={toggling}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row testEntryContainer">

                    <div className="col-md-12 mb-4 testHistoryBlock">
                        <div className="testEntryDetails">
                            <h4>Teat Details</h4>
                            <hr />
                            <p>Course Name: {course}</p>
                            <p>Test Number: {test_no}</p>
                            <p>Test Duration: {duration} Mins</p>
                            <p>Total Questions: {data[0]}</p>
                            <Link to={`/users/test/${course}/${test_no}/${duration}`}><button className='btn btn-success btn-lg start-btn'><i className="fa-solid fa-play fa-beat-fade"></i> Start</button></Link>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
