import React from 'react'
import './css/UnlockQuiz.css';
import Sidebar from './AdminSidebar';
import toggle from './js/toggle.js';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from "axios";
  
export default function UnlockQuiz(props) {

    const [data, setData] = useState([]);

    const loadData = async () => {
        try {
            const response = await axios.get("/allsubject/subjectlist");
            setData(response.data);
        }
        catch (err) {
            // console.log(err);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    function Hovering() {
        var element = document.getElementById("admin_unlockTest");
        element.classList.add("hovered");
    }

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="adminMain" onLoad={Hovering}>

                <div className="adminTopbar" onLoad={toggle}>

                    <div className="toggle" onClick={toggle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row quizCourseCards">
   
                {data.map((item, index) => {
                        return (

                    <div className="col-md-3 p-0" key={item.subjects}>
                        <Link to={`/admin/QuizTests/${item.subjects}`}>
                            <div className='courseCard'>
                                <div>
                                    {/* <div className="numbers">{index + 1}</div> */}
                                    <div className="numbers">{item.students}</div>
                                    <div className="cardName">{item.subjects}</div>
                                </div>
                                <div className="iconBx">
                                    <i className={item.icons}></i>
                                    
                                </div>
                            </div>
                        </Link>
                    </div>
                    
                    )}
                    )}


                </div>

            </div>
        </div>
    )
}
