import React from 'react'
import './css/QuizTests.css';
import Sidebar from './AdminSidebar';
import toggle from './js/toggle.js';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function QuizTests(props) {

    const [data, setData] = useState([]);

    const { subjects } = useParams("");

    const loadData = async () => {
        try {
            const response = await axios.get(`/quizlist/quizlistnumber/${subjects}`);
            setData(response.data);
            // console.log(response.data);
        }
        catch (err) {
            // console.log(err);
        }
    };

    useEffect(() => {
        loadData();
    });

    function Hovering() {
        var element = document.getElementById("admin_unlockTest");
        element.classList.add("hovered");
    }

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="adminMain" onLoad={Hovering}>

                <div className="adminTopbar" onLoad={toggle}>

                    <div className="toggle" onClick={toggle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>


                <div className="row quizTestsCards">


                    {data.map((item, index) => {
                        return (

                            <div className="col-md-3 p-0" key={item.quiz_number}>
                                <Link to={`/admin/StudentUnlockQuizList/${item.course}/${item.quiz_number}`}>
                                    <div className='testsCard'>
                                        <div>
                                            <div className="numbers">{item.quiz_number}</div>
                                            <div className="cardName">{item.course}</div>
                                        </div>
                                        <div className="iconBx">
                                            <i className="fa-solid fa-c"></i>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                         )
                    }
                    )}

                </div>
            </div>
        </div>
    )
}