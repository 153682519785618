import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        justifyContent: 'center',
        marginTop: 15
    },
    reportTitle:{
        color: 'rgba(48,50,127,255)',
        fontSize:18,
        fontWeight:'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    reportTi:{
        color: 'rgba(48,50,127,255)',
        fontSize:11,
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    headding:{
        color: 'rgba(48,50,127,255)',
        fontSize:16,
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
        marginTop:18,
        textDecoration: 'underline', 
    }
  });

export default function InvoiceTitle({title}) {
  return (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>integration it service pvt. ltd</Text>
        <Text style={styles.reportTi}>Durga Nagar, Main Road, Vidisha (M.P.) - 46001</Text>
        <Text style={styles.reportTi}>Call :- 9098351146 , 9174229678</Text>
        <Text style={styles.headding}>{title}</Text>
    </View>
  )
}
