import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle';
import InvoiceThankYouMsg from './InvoiceThankYouMsg';
import InvoiceTableHeader from './InvoiceTableHeader';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }
});


export default function InvoicePage({id}) {
    
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle title='Registration Form' />
                <InvoiceTableHeader id={id} />
                <InvoiceThankYouMsg id={id}/>
            </Page>
        </Document>
    )
}
