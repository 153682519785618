import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Login from './components/Login';
import Register from './components/Register';
import EnrolledLogin from './components/EnrolledLogin';
import { AuthContext } from "./context/authContext";
import React from "react";
import { useContext } from "react";

// users componentss
import Dashboard from './components/users/Dashboard';
import TestList from './components/users/TestList';
import CourseList from './components/users/CourseList';
import TestEntry from './components/users/TestEntry';
import Test from './components/users/Test';
import TestHistory from './components/users/TestHistory';
import TestDetails from './components/users/TestDetails';
import Rank from './components/users/Rank';

// admin components
import AdminDashboard from './components/admin/AdminDashboard';
import UploadQuiz from './components/admin/UploadQuiz';

import ScrollToTop from './components/ScrollToTop';
import UnlockQuiz from './components/admin/UnlockQuiz';
import QuizTests from './components/admin/QuizTests';
import StudentUnlockQuizList from './components/admin/StudentUnlockQuizList';
import StudentUnlockDetails from './components/admin/StudentUnlockDetails';
import EnrollStudents from './components/admin/EnrollStudents';
import EnrolledStudents from './components/admin/EnrolledStudents';
import StudentDetails from './components/admin/StudentDetails';
import AdminLogin from './components/admin/AdminLogin';
import Invoice from './components/invoice/Invoice';

function App() {

  const { currentUser } = useContext(AuthContext);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/enrolled_login" element={<EnrolledLogin />} />
      
        <Route path="/users/test_list/:course" element={<TestList PageName="Test List" />} />,
        <Route path="/users/course_list" element={<CourseList PageName="Course List" />} />,
        <Route path="/users/test/:course/:test_no/:duration" element={<Test PageName="Test" />} />,
        <Route path="/users/testEntry/:course/:test_no" element={<TestEntry PageName="Test" />} />,
        <Route path="/users/testHistory" element={<TestHistory PageName="Test History" />} />,
        <Route path="/users/testDetails/:test_number/:course_name" element={<TestDetails PageName="Test Details" />} />,
        <Route path="/users/rank" element={<Rank PageName="Rank" />} />
      
        {/* admin */}
        <Route path="/admin/adminLogin" element={<AdminLogin PageName="Login Admin" />} />
        <Route path="/admin/adminDashboard" element={<AdminDashboard PageName="Welcome Admin" />} />
        <Route path="/admin/UploadQuiz" element={<UploadQuiz PageName="Upload Quiz" />} />
        <Route path="/admin/UnlockQuiz" element={<UnlockQuiz PageName="Unlock Quiz" />} />
        <Route path="/admin/QuizTests/:subjects" element={<QuizTests PageName="Quiz List" />} />
        <Route path="/admin/StudentUnlockQuizList/:course/:quiz_number" element={<StudentUnlockQuizList PageName="Student List" />} />
        <Route path="/admin/StudentUnlockDetails/:contact_number/:quiz_number/:course" element={<StudentUnlockDetails PageName="Student Details" />} />
        <Route path="/admin/EnrollStudents" element={<EnrollStudents PageName="Enroll Student" />} />
        <Route path="/admin/EnrolledStudents" element={<EnrolledStudents PageName="Enrolled Students" />} />
        <Route path="/admin/StudentDetails/:id" element={<StudentDetails PageName="Student Details" />} />
        <Route path="/invoice/Invoice/:id" element={<Invoice PageName="Invoice" />} />

        {/* users */}
        {currentUser ? (
          <Route path="/users/dashboard" element={<Dashboard PageName="Dashboard" />} />
        ) : (
          <Route path="/login" element={<Login />} />
        )
        }
      </Routes>
    </>
  );
}

export default App;
