import './css/EnrolledStudents.css';
import Sidebar from './AdminSidebar';
import toggle from './js/toggle.js';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';

export default function EnrolledStudents(props) {


    const [data, setData] = useState([]);



    const loadData = async () => {
        try {
            const response = await axios.get("/enrollStudents/alluserlist");
            setData(response.data);
            // console.log(response.data);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadData();
    }, []);




    function Hovering() {
        var element = document.getElementById("admin_enrolledStudents");
        element.classList.add("hovered");
    }

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="adminMain" onLoad={Hovering}>

                <div className="adminTopbar" onLoad={toggle}>

                    <div className="toggle" onClick={toggle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row EnrolledStudentsList">
                    <div className="EnrolledStudents">

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Sno.</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Registration Date</th>
                                    <th scope="col">Contact No.</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Institute</th>
                                    <th scope="col">Current Course</th>
                                    <th scope="col">Fees</th>
                                    <th scope="col">Payment</th>
                                    <th scope="col">Due</th>
                                    <th scope="col">Reference</th>
                                    <th scope="col">Form</th>
                                    <th scope="col">View</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {data.map((item, index) => {
                                    return (

                                        <tr key={item.id}>
                                            <th scope="row"> {index + 1}</th>
                                            <td>{item.student_name}</td>
                                            {/* <td>{item.AddedOnDate}</td> */}
                                            <td>{(new Date(item.AddedOnDate)).toLocaleDateString()}</td>
                                            <td>{item.contact_number}</td>
                                            <td>{item.student_email}</td>
                                            <td>{item.student_institute}</td>
                                            <td>{item.current_course}</td>
                                            <td>{item.total_fees}/-</td>
                                            <td>{item.payment}/-</td>
                                            <td>{item.payment_due}/-</td>
                                            <td>{item.reference}</td>
                                            <td>
                                                <Link to={`/invoice/Invoice/${item.id}`}>
                                                    <button className='btn btn-sm btn-warning'><i className="fa-solid fa-file-pdf"></i> Form</button>
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={`/admin/StudentDetails/${item.id}`}>
                                                    <button className='btn btn-sm btn-secondary'><i className="fa-solid fa-eye"></i> View</button>
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>




        </div>


    )
}
