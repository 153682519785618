import React from 'react'
import './css/Rank.css';
import Sidebar from './Sidebar';
import toggling from './js/toggle.js';

export default function Rank(props) {

    function Hovering() {
        var element = document.getElementById("user_rank");
        element.classList.add("hovered");
    }

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="main" onLoad={Hovering}>

                <div className="topbar" onLoad={toggling}>

                    <div className="toggle" onClick={toggling}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row rankContainer">

                    <div className="rankBlock">

                        <div className="row topThree mb-4">
                            <div className="col-4 second">
                                <div className="rankInnerBlock">
                                    <div className="rank">
                                        <h5>#2</h5>
                                    </div>
                                    <img src={require('../../images/mahendra.png')} alt="userimage" />
                                    <div className="rankerDetails">
                                        <p>Mahendra Patankar</p>
                                        <span>Score: 40436</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 first">
                                <div className="rankInnerBlock">
                                    <div className="rank">
                                        <h5>#1</h5>
                                    </div>
                                    <img src={require('../../images/abhishek.jpeg')} alt="userimage" />
                                    <div className="rankerDetails">
                                        <p>Abhishek raikwar</p>
                                        <span>Score: 45406</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 third">
                                <div className="rankInnerBlock">
                                    <div className="rank">
                                        <h5>#3</h5>
                                    </div>
                                    <img src={require('../../images/vijay.png')} alt="userimage" />
                                    <div className="rankerDetails">
                                        <p>Vijay Dangi</p>
                                        <span>Score: 35473</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rankList">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Rank</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Score</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    <tr>
                                        <th className='rank' scope="row">#1</th>
                                        <td>Abhishek Raikwar</td>
                                        <td>45406</td>
                                    </tr>
                                    <tr>
                                        <th className='rank' scope="row">#2</th>
                                        <td>Mahendra Patankar</td>
                                        <td>40436</td>
                                    </tr>
                                    <tr>
                                        <th className='rank' scope="row">#3</th>
                                        <td>Vijay Dangi</td>
                                        <td>35473</td>
                                    </tr>
                                    <tr>
                                        <th className='rank' scope="row">#4</th>
                                        <td>Shabaz Khan</td>
                                        <td>29473</td>
                                    </tr>
                                    <tr>
                                        <th className='rank' scope="row">#5</th>
                                        <td>Aman Suryawanshi</td>
                                        <td>25473</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}
