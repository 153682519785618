import React from 'react'
import about from '../images/about.png';
import './css/About.css';
import Navbar from './Navbar';
import Footer from './Footer';

export default function About() {
    // Script for page navigation
    function Focus() {
        var element = document.getElementById("AboutPage");
        document.getElementById("HomePage").classList.remove("active");
        document.getElementById("TutorialPage").classList.remove("active");
        document.getElementById("RegisterPage").classList.remove("active");
        document.getElementById("LoginPage").classList.remove("active");
        element.classList.add("active");
    }
    return (
        <>
            <Navbar />
            <div className="container-fluid p-0 mainContainer" onLoad={Focus}>

                <div className="container-fluid p-0" id="about_first_container">
                    <div className="container">
                        <div className="row aboutFirstBlock">
                            <img src={about} alt="images" />
                            <h2>Our mission is to help users learn programming more easily.</h2>
                            <p>We are a small team of passionate developers constantly working to create programming resources that's accurate and easier to understand.</p>
                            <p>We understand that creating textual content on the web to learn programming is not enough. To improve the learning experience of our users, we are working on the following projects:</p>
                        </div>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 130">
                        <path fill="#282a35" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                    </svg>

                </div>

                <div className="container-fluid p-0" id="about_second_container">
                    <div className="container">
                        <div className="row aboutSecondBlock">
                            <div className="col-md-4 cardBolck mb-4">
                                <div className="card">
                                    <h5>WORKING PROJECTS</h5>
                                    <hr />
                                    <p>The goal as a company is to have customer service that is not just the best but legendary.</p>
                                    <button className='btn btn-primary'>Start Learn</button>
                                </div>
                            </div>
                            <div className="col-md-8 projectBlock">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>1. An effective learning model</h3>
                                        <p>We analysed retention and learning patterns of users who learned programming.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h3>2. Best methods of learning</h3>
                                        <p>We use best methodology for our users and try to make programming easy and simple.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>3. Interaction Based</h3>
                                        <p>Unlike textbooks, mobile platforms are supposed to be interactive! Only then do they feel live, and are able to keep us interested in the learning process.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h3>4. Adaptive Learning</h3>
                                        <p>The application is tuned to understand your learning needs and accordingly crafted to adapt with your learning style and the time you are able to allocate for learning.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 130">
                        <path fill="#e8efeb" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                    </svg>

                </div>

                <div className="container-fluid p-0" id="about_third_container">
                    <div className="container">
                        <div className="row aboutThirdBlock">
                            <div className="col-lg-6 textBlock">
                                <h2>Our Leaders</h2>
                                <hr />
                                <p>We're a telent-led organization that's always on the looking for passionate, curious and creative forward thinkers. Explore creere with us and unlock your potential.</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="row photoBlock">
                                    <div className="col-sm-6">
                                        <img src={require('../images/director.jpg')} alt="director" />
                                        <h4>Koushalesh Acharya</h4>
                                        <p>Director</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <img src={require('../images/co_director.jpg')} alt="director" />
                                        <h4>Vipin Gourkhede</h4>
                                        <p>Co-Director</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 130">
                        <path fill="#282a35" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                    </svg> */}

                </div>

                {/* <div className="container-fluid p-0" id="about_forth_container">
                    <div className="container">
                        <h5>IITS Core Team</h5>
                        <div className="row aboutForthBlock">
                            <div className="col-sm-4">
                                <img src={require('../images/mahendra.png')} alt="HR" />
                                <h4>Mahendra Patankar</h4>
                                <p>Designation: HR</p>
                            </div>
                            <div className="col-sm-4">
                                <img src={require('../images/abhishek.jpeg')} alt="Manager" />
                                <h4>Abhishek Raikwar</h4>
                                <p>Designation: Project Manager</p>
                            </div>
                            <div className="col-sm-4">
                                <img src={require('../images/vijay.png')} alt="Developer" />
                                <h4>Vijay Dangi</h4>
                                <p>Designation: Developer</p>
                            </div>
                        </div>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 90">
                        <path fill="#e8efeb" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                    </svg>

                </div> */}

                <div className="container-fluid p-0" id="about_fifth_container">
                    <div className="container">
                        <div className="row aboutFifthBlock">
                            <div className="col-md-6" style={{ textAlign: "center" }}>
                                <img src={require('../images/about2.png')} alt="Developer" />
                            </div>
                            <div className="col-md-6">
                                <h2>Wanna start coding?</h2>
                                <hr />
                                <p>1. With research backed learning technique by help of industry experts.</p>
                                <p>2. We have crafter the perfect learning path for you to learn programming.</p>
                                <p>3. Put Your Knowledge into Action.</p>
                            </div>
                        </div>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 90">
                        <path fill="#282a35" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                    </svg>

                </div>

            </div >
            <Footer />
        </>
    )
}
