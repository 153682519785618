import './css/StudentDetails.css';
import Sidebar from './AdminSidebar';
import toggle from './js/toggle.js';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

export default function StudentDetails(props) {

    const [data, setData] = useState([]);

    const loadData = async () => {
        try {
            const response = await axios.get("/allsubject/subjectlist");
            setData(response.data);
            // console.log(response.data);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadData();
    }, []);


    const [user, setUser] = useState([]);

    const { id } = useParams("");

    const getdata = async () => {
        const res = await fetch(`/enrollStudents/userdetails/${id}`);
        const data = await res.json();
        // console.log(data);

        if (res.status === 422 || !data) {
            // console.log("error");
        }
        else {
            setUser(data[0])
            // console.log("get data");
        }
    }

    useEffect(() => {
        getdata();
    });


    function Hovering() {
        var element = document.getElementById("admin_enrolledStudents");
        element.classList.add("hovered");
    }

    function showUpdateDetailsBlock() {
        var block = document.getElementById("updateDetailsBlock");
        block.style.display = "block";
    }

     // Array of all options
     const optionList = data.map((item, index) => {
        return (
            { value: item.subjects, label: item.subjects }
        )
    })

    // Function triggered on selection
    function handleSelect(data)
     {
        setSelectedOptions(data);
  }

  //Update data

    const [selectedOptions, setSelectedOptions] = useState("");
    const [updateFees, setupdateFees] = useState("");
    const [updateFeesPaid, setupdateFeesPaid] = useState("");
    const [updateDue, setupdateDue] = useState("");
    const [updateCourse, setupdateCourse] = useState("");
    const [updateActivity, setupdateActivity] = useState("");

   
    const handleSubmit = async (e) => {
        const OptionLenght = selectedOptions.length;
        let selvalue = "";
        for (let i = 0; i < OptionLenght; i++) {
            selvalue += selectedOptions[i].value + ",";
        }
        e.preventDefault();

        try {
            await axios.put(`/enrollStudents/userdetailsupdate/${id}`, {
                updateFees: updateFees ? updateFees : user.total_fees,
                updateFeesPaid: updateFeesPaid ? updateFeesPaid : user.payment,
                updateDue: updateDue ? updateDue : user.payment_due,
                updateCourse: updateCourse ? updateCourse : user.current_course,
                selvalue: selvalue ? selvalue : user.all_courses,
                updateActivity: updateActivity ? updateActivity : user.status,
            });

            alert("Details Updated!");
        }
        catch (err) {

            alert("Error");
        }
    }

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="adminMain" onLoad={Hovering}>

                <div className="adminTopbar" onLoad={toggle}>

                    <div className="toggle" onClick={toggle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row studentDetailsContainer">
                    <div className="studentDetailsBlock">
                        <div className="studentDetailsHead">
                            <h4>Student Details</h4>
                            <hr />
                        </div>
                        <div className="studentDetailsBody px-3">
                            <div className="row">

                                <div className="col-md-5">

                                    <div className="StudentPhoto">
                                        <center><img src={require('../../images/profile.jpeg')} alt="userImage" /></center>
                                    </div>

                                    <div className="row">
                                        <div className="col"><span>Student Name: </span></div>
                                        <div className='col'><span>{user.student_name}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Contact Number:</span></div>
                                        <div className='col'><span>{user.contact_number}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Email:</span></div>
                                        <div className='col'><span>{user.student_email}</span></div>
                                        <hr />
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col"><span>Registration Date:</span></div>
                                        <div className='col'><span>{(new Date(user.AddedOnDate)).toLocaleDateString()}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Institute:</span></div>
                                        <div className='col'><span>{user.student_institute}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Current Course:</span></div>
                                        <div className='col'><span>{user.current_course}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>All Courses:</span></div>
                                        <div className='col'><span>{user.all_courses}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Fees:</span></div>
                                        <div className='col'><span>{user.total_fees}/-</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Fees Paid:</span></div>
                                        <div className='col'><span>{user.payment}/-</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Due:</span></div>
                                        <div className='col'><span>{user.payment_due}/-</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Reference:</span></div>
                                        <div className='col'><span>{user.reference}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row" style={{ justifyContent: "center" }}>
                                        <button className='btn btn-sm btn-primary make_changes_btn' onClick={showUpdateDetailsBlock} style={{ maxWidth: "400px" }}>Make Changes</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="studentDetailsBlock mt-3" id='updateDetailsBlock' style={{ display: "none" }}>
                        <div className="heading">
                            <h4>Update Details</h4>
                            <hr />
                        </div>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-floating mb-2">
                                    <input type="number" name="fees" onChange={(e) => setupdateFees(e.target.value)} className="form-control" id="fees" placeholder="Enter Fees" />
                                    <label htmlFor="fees">Enter Fees Ammount</label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-floating mb-2">
                                    <input type="number" name="fees_paid" onChange={(e) => setupdateFeesPaid(e.target.value)} className="form-control" id="fees_paid" placeholder="Enter Fees Ammount" />
                                    <label htmlFor="fees_paid">Enter Pay Ammount</label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-floating mb-2">
                                    <input type="number" name="due" onChange={(e) => setupdateDue(e.target.value)} className="form-control" id="due" placeholder="Enter Due Ammount" />
                                    <label htmlFor="due">Enter Due Ammount</label>
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-floating mb-2">


                                    <select className="form-select" name="current_course" onChange={(e) => setupdateCourse(e.target.value)} id="course" >
                                        <option hidden></option>
                                        {data.map((item, index) => {
                                            return (
                                                <option key={item.subjects}>{item.subjects}</option>
                                            )
                                        }
                                        )}
                                    </select>

                                    <label htmlFor="course">Enter Course Name</label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-floating mt-2">

                                    <Select
                                        name="update_course"
                                        options={optionList}
                                        placeholder="Select"
                                        value={selectedOptions}
                                        onChange={handleSelect}
                                        // onChange={handleChange}
                                        isSearchable={true}
                                        isMulti >
                                    </Select>

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-floating mb-2">


                                    <select className="form-select" name="Activity" onChange={(e) => setupdateActivity(e.target.value)} id="activity" >
                                        <option hidden></option>
                                        <option value="Running">Running</option> 
                                        <option value="Completed">Completed</option> 
                                    </select>

                                    <label htmlFor="activity">Select Activity Name</label>
                                </div>
                            </div>


                            <div className="col-md-6">
                                <button className='btn btn-primary mt-2 save_changes_btn' onClick={handleSubmit}>Save Changes</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
