import './css/Navbar.css';
import { Link } from 'react-router-dom';
import logo from '../images/iitscodelogo.png';
// import { AuthContext } from "../context/authContext";
import React from "react";
import { memo } from "react";

const Navbar = () => {

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <a className="navbar-brand" href="/"><img src={logo} alt="logo" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link px-0 py-1 mx-4" id="HomePage" to="/"><i class="fa-solid fa-house"></i> Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link px-0 py-1 mx-4" id="AboutPage" to="/about"><i class="fa-solid fa-circle-info"></i> About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link px-0 py-1 mx-4" id="TutorialPage" to="/"><i class="fa-solid fa-book"></i> Tutorial</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link px-0 py-1 mx-4" id="RegisterPage" to="/register"><i class="fa-solid fa-pen-to-square"></i> Register</Link>
                        </li>
                        <li className="nav-item">
                         <Link className="nav-link px-0 py-1 mx-4" id="LoginPage" to="/login"><i class="fa-solid fa-right-to-bracket"></i> Login</Link>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default memo(Navbar)
