import React, { useContext, useEffect, useState } from 'react';
import './css/TestList.css';
import Sidebar from './Sidebar';
import toggling from './js/toggle.js';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/authContext';

export default function TestList(props) {

    function Hovering() {
        var element = document.getElementById("user_test");
        document.getElementById("user_dashboard").classList.remove("hovered");
        element.classList.add("hovered");
    }

    const { course } = useParams("");

    const { currentUser } = useContext(AuthContext);
    let studentEmail = currentUser.student_email;

    const [data, setData] = useState([]);

    const loadTestList = async () => {
        try {
            const res = await axios.get(`/test/testlist/${course}/${studentEmail}`);
            setData(res.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        loadTestList();
    });

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="main" onLoad={Hovering}>

                <div className="topbar" onLoad={toggling}>

                    <div className="toggle" onClick={toggling}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row cardBox">

                    {data.map((item, index) => {
                        return (
                            <div className="col-md-3" key={index}>
                                <Link  to={`/users/testEntry/${course}/${item.test_number}`} style={{ textDecoration: "none" }}>
                                    <div className="card">
                                        <div>
                                            <div className="numbers">{item.test_number}</div>
                                            <div className="cardName">{course}</div>
                                        </div>
                                        <div className="iconBx">
                                            <i className="fa-solid fa-laptop-code"></i>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}

                </div>

            </div>
        </div>
    )
}
