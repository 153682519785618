import React from 'react'
import './css/StudentUnlockQuizList.css';
import Sidebar from './AdminSidebar';
import toggle from './js/toggle.js';
import axios from "axios";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


export default function StudentUnlockQuizList(props) {

    const [data, setData] = useState([]);
  
    const { course  } = useParams("");
    const { quiz_number  } = useParams("");
   
    const loadData = async () => {
        try {
            const response = await axios.get(`/quizlist/studentlist/${course}`);
            setData(response.data);
            // console.log(response.data.student_name);
        }
        catch (err) {
            // console.log(err);
        }
    };

    useEffect(() => {
        loadData();
    });

    function Hovering() {
        var element = document.getElementById("admin_unlockTest");
        element.classList.add("hovered");
    }

    
    return (
        <div className="container-fluid gx-0"> 
            <Sidebar />
            <div className="adminMain" onLoad={Hovering}>

                <div className="adminTopbar" onLoad={toggle}>

                    <div className="toggle" onClick={toggle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row StudentUnlockQuizList">
                    <div className="StudentList">

                       <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Sno.</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Number</th>
                            
                                    <th scope="col">Course</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>


                            <tbody className="table-group-divider">
                                {data.map((item, index) => {

                                    return (

                                        <tr key={item.contact_number}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.student_name}</td>
                                            <td>{item.student_email}</td>
                                            <td>{item.contact_number}</td>
                                            
                                            <td>{course}</td>
                                            <td> <Link to={`/admin/StudentUnlockDetails/${item.contact_number}/${quiz_number}/${course}`}><button  className='btn btn-sm btn-warning'><i className="fa-solid fa-unlock-keyhole"></i>  </button> </Link></td>
                                        </tr>

                                    )
                                }
                                )}

                            </tbody>

                        </table>
                         </div>
                </div>

            </div>
        </div>
    )
}
