import React from 'react'
import './css/Footer.css';
import logo from '../images/iitscodelogo.png';
import { memo } from "react";
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="container-fluid footer">
            <div className="container">
                <div className='row pt-3'>
                    <div className='col-md-4'>
                        <div className="innerBlock">
                            <div className="logoBlock">
                                <img src={logo} alt="logo" />
                            </div>
                            <div className="aboutBlock">
                                <div className="heading">
                                    <h5>About the Company</h5>
                                </div>
                                <div className="details">
                                    <p><b>Integration IT Services</b> is customer centric website design and development company. Our core values drive us towards achieving 100% customer satisfaction.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="innerBlock">
                            <div className="heading">
                                <h5>Sitemap</h5>
                            </div>
                            <div className="details">
                                <div className="row">
                                    <div className="col">
                                        <ul>
                                            <li>Home</li>
                                            <li>About</li>
                                            <li>Contact</li>
                                            <li>Courses</li>
                                        </ul>
                                    </div>
                                    <div className="col">
                                        <ul>
                                            <li>Certificate</li>
                                            <li>Register</li>
                                            <li><Link to="/enrolled_login"> Login</Link></li>
                                            <li>Sitemap</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="innerBlock">
                            <div className="heading">
                                <h5>Contact Details</h5>
                            </div>
                            <div className="details">
                                <div className="row" style={{ alignItems: 'center' }}>
                                    <div className="col-1">
                                        <i class="fa-solid fa-map-location-dot"></i>
                                    </div>
                                    <div className="col">
                                        <p className='m-0'>Durganagar Main Road, Near Durga Temple, Vidisha</p>
                                        <p className='m-0'>FB 40, 41, Mansarovar Complex Bhopal (M.P.)</p>
                                    </div>
                                </div>
                                <div className="row my-3" style={{ alignItems: 'center' }}>
                                    <div className="col-1">
                                        <i class="fa-solid fa-phone-volume"></i>
                                    </div>
                                    <div className="col">
                                        <p className='m-0'>Phone : 0755 4287043 (Bhopal)</p>
                                        <p className='m-0'>Phone : 9098351146 (Vidisha)</p>
                                    </div>
                                </div>
                                <div className="row" style={{ alignItems: 'center' }}>
                                    <div className="col-1">
                                        <i class="fa-solid fa-envelope-open-text"></i>
                                    </div>
                                    <div className="col">
                                        <a style={{textDecoration: 'none'}} href="mailto:info@integrationitservices"><p className='m-0'>info@integrationitservices.com</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="row pb-3">
                    <div className="col-md">
                        <p className='copiright'>Copyright © 2023 Integration IT Services Pvt. Ltd.</p>
                    </div>
                    <div className="col-md social-icons" style={{ display: 'flex', justifyContent: 'end' }}>
                        <a href="/"><i class="fa-brands fa-facebook-f mx-2"></i></a>
                        <a href="/"><i class="fa-brands fa-instagram mx-2"></i></a>
                        <a href="/"><i class="fa-brands fa-linkedin-in mx-2"></i></a>
                        <a href="/"><i class="fa-brands fa-twitter mx-2"></i></a>
                        <a href="/"><i class="fa-brands fa-google mx-2"></i></a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default memo(Footer)