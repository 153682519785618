import './css/Sidebar.css';
import { Link } from 'react-router-dom';
import { AuthContext } from "../../context/authContext";
import React, { useContext } from "react";
import { memo } from "react";

function Sidebar() {

    const { logout } = useContext(AuthContext);

    return (
        <div className="navigation">
            <ul>
                <li>
                    <Link to="/users/dashboard">
                        <span className="title navHeadTitle">IITS</span>
                        <span className="title">Integration IT Services</span>
                    </Link>
                </li>
                <li id='user_dashboard'>
                    <Link to="/users/dashboard">
                        <span className="icon">
                            <i className="fa-solid fa-house"></i>
                        </span>
                        <span className="title">Dashboard</span>
                    </Link>
                </li>
                <li id='user_test'>
                    <Link to="/users/course_list">
                        <span className="icon">
                            <i className="fa-regular fa-paste"></i>
                        </span>
                        <span className="title">Tests</span>
                    </Link>
                </li>
                <li id='user_test_history'>
                    <Link to="/users/testHistory">
                        <span className="icon">
                            <i className="fa-solid fa-clock-rotate-left"></i>
                        </span>
                        <span className="title">Quiz History</span>
                    </Link>
                </li>
                <li id='user_rank'>
                    <Link to="/users/rank">
                        <span className="icon">
                            <i className="fa-solid fa-ranking-star"></i>
                        </span>
                        <span className="title">Rank</span>
                    </Link>
                </li>
                <li>
                    <Link onClick={logout} to="/">
                        <span className="icon">
                            <i className="fa-solid fa-right-from-bracket"></i>
                        </span>
                        <span className="title">Sign Out</span>
                    </Link>


                </li>
            </ul>
        </div>
    )

}

export default memo(Sidebar)
