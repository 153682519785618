import React from 'react';
import './css/Login.css';
import { useState } from "react";
import { useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Navbar from './Navbar';
import Footer from './Footer';

export default function Login() {

    const [inputs, setInputs] = useState({
        email: "",
        password: "",
    });

    // const [err, setError] = useState(null);

    const navigate = useNavigate();

    const { login } = useContext(AuthContext);

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(inputs)
            navigate("/users/dashboard");
        } catch (err) {
            // setError(err.response.data);
            alert("Wrong username or password");
        }

    };

    function Focus() {
        var element = document.getElementById("LoginPage");
        document.getElementById("HomePage").classList.remove("active");
        document.getElementById("TutorialPage").classList.remove("active");
        document.getElementById("RegisterPage").classList.remove("active");
        document.getElementById("AboutPage").classList.remove("active");
        element.classList.add("active");
    }

    return (
        <>
            <Navbar />
            <div className="container-fluid" id="content_11" onLoad={Focus}>
                <div className="container" id="content_12">
                    <div className="row" id="login_main_block">

                        <div className="col-md-6">
                            <div className="loginFormBlock">
                                <div className="loginInnerBlock">
                                    <h1 className="heading">Login</h1>
                                    <hr />
                                    <div className="login_formBlock">
                                        <form>

                                            <div className="mb-3">
                                                <input type="email" className="form-control" placeholder="Enter your registered Email ID" name="email" onChange={handleChange} id="exampleInputText2" required />
                                            </div>

                                            <div className="mb-3">
                                                <input type="password" className="form-control" placeholder="Password" name="password" onChange={handleChange} id="exampleInputText1" autoComplete="on" required />
                                            </div>
                                            <div className="mb-3">
                                                <span href="/">Forgot your password ?</span>
                                            </div>

                                            <button style={{ fontWeight: '500' }} className="btn btn-primary" onClick={handleSubmit}>Login</button>

                                        </form>

                                        <div style={{ padding: '10px 0' }}>
                                            <p>Don't have account then register by <Link to="/register">click here</Link></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6" id="content_13">
                            <img src={require('../images/login.png')} alt="director" />
                        </div>

                    </div>
                </div>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 65 1439 90">
                <path fill="#282a35" fillOpacity="1" d="M0,96L80,90.7C160,85,320,75,480,90.7C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
            </svg>

            <Footer />
        </>

    )
}


