import React from 'react';
import './css/AdminLogin.css';
import { useState } from "react";
// import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
// import { AuthAdmin } from "../../context/authAdmin";

export default function AdminLogin() {

	const [inputs, setInputs] = useState({
        email: "",
        password: "",
    });

	const navigate = useNavigate();

    // const { loginn } = useContext(AuthAdmin);

	const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

	const handleSubmit = async (e) => {
        e.preventDefault();
        // try {
        //     await loginn(inputs)
        //     navigate("/admin/adminDashboard");
        // } catch (err) {
        //     // setError(err.response.data);
        //     alert("Wrong username or password");
        // }
    };

	return (
		<>
			<img src={require('../../images/wave2.png')} alt='bg' className="wave" />

			<div className="containerrr">
				<div className="imggg">
					<img src={require('../../images/bg1.png')} alt='bgg' />

				</div>
				<div className="login-contentt">
					<form className="adminloginform">
						<img src={require('../../images/avatar.png')} alt='avatar' />
						<h2 className="title">ADMIN LOGIN</h2>
						<div className="inputt-div one">
							<div className="iii">
								<i className="fas fa-user"></i>
							</div>
							<div className="div">
								<input type="email" name="email" placeholder='Email-id' className="input" onChange={handleChange} />
							</div>
						</div>
						<div className="inputt-div pass">
							<div className="iii">
								<i className="fas fa-lock"></i>
							</div>
							<div className="div">
								<input type="password" name="password" placeholder='Password' className="input" onChange={handleChange} />
							</div>
						</div>
					
						<button className="buttonn" onClick={handleSubmit}>
							<span className="transitionn"></span>
							<span className="gradientt"></span>
							<span className="labell">Button</span>
						</button>
					</form>
				</div>
			</div>
		</>
	)
}
