import './css/AdminSidebar.css';
import { Link } from 'react-router-dom';
import React from "react";
import { memo } from "react";

function AdminSidebar() {

    return (
        <div className="adminNavigation">
            <ul>
                <li className='brand_LI'>
                    <Link to="/admin/adminDashboard">
                        <span className="title navHeadTitle">IITS</span>
                        <span className="title">Admin Panel</span>
                    </Link>
                </li>
                <li id='admin_adminDashboard'>
                    <Link to="/admin/adminDashboard">
                        <span className="icon">
                            <i className="fa-solid fa-house"></i>
                        </span>
                        <span className="title">Dashboard</span>
                    </Link>
                </li>
                <li id='admin_uploadTest'>
                    <Link to="/admin/UploadQuiz">
                        <span className="icon">
                            <i className="fa-solid fa-upload"></i>
                        </span>
                        <span className="title">Upload Quiz</span>
                    </Link>
                </li>
                <li id='admin_unlockTest'>
                    <Link to="/admin/UnlockQuiz">
                        <span className="icon">
                            <i className="fa-solid fa-unlock-keyhole"></i>
                        </span>
                        <span className="title">Unlock Quiz</span>
                    </Link>
                </li>
                <li id='admin_enrollStudent'>
                    <Link to="/admin/EnrollStudents">
                        <span className="icon">
                            <i className="fa-solid fa-user-pen"></i>
                        </span>
                        <span className="title">Enroll Student</span>
                    </Link>
                </li>
                <li id='admin_enrolledStudents'>
                    <Link to="/admin/EnrolledStudents">
                        <span className="icon">
                            <i className="fa-solid fa-users"></i>
                        </span>
                        <span className="title">Enrolled Students</span>
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        <span className="icon">
                            <i className="fa-solid fa-right-from-bracket"></i>
                        </span>
                        <span className="title">Sign Out</span>
                    </Link>
                </li>
            </ul>
        </div>
    )

}

export default memo(AdminSidebar)
