import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle';
import { useEffect, useState } from 'react';


const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 8
    },
    reportTitle:{
        fontSize: 12,
        textAlign: 'center',
        color: 'rgba(48,50,127,255)'
    },
    signature:{
      flexDirection: 'row',
      justifyContent:'flex-end',
      marginTop:10
  },
  signaturee:{
      fontSize: 12,
      textAlign: 'center',
      color: 'rgba(48,50,127,255)',
  },
  lines:{
   color: 'rgba(48,50,127,255)',
   marginTop:8
  },
  titlecontent:{
    marginTop:4
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1.5, 
    borderRightWidth: 0, 
    borderBottomWidth: 0, 
    marginTop:18,
    color: 'rgba(48,50,127,255)',
    borderColor: 'rgba(48,50,127,255)',
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "50%", 
    borderStyle: "solid",
    borderColor: 'rgba(48,50,127,255)', 
    borderWidth: 1.5, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCell: { 
    marginLeft: 15, 
    marginTop: 5, 
    fontSize: 10,
    fontWeight: 'bold',    
  },
  serial: {
    flexDirection:'row',
    justifyContent: 'center',
    color:'rgba(48,50,127,255)'
  }
  });


export default function InvoiceThankYouMsg({id}) {

  const [user, setUser] = useState([]);
  
  const getdata = async () => {
      const res = await fetch(`/enrollStudents/userdetails/${id}`);
      const data = await res.json();

      if (res.status === 422 || !data) {
          // console.log("error");
      }
      else {
          setUser(data[0])
          // console.log("get data");
      }
  }

  useEffect(() => {
      getdata();
  }, []);


  return (
   <View>
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Note :- Fees once paid will not be refunded !</Text>
    </View>
   <View style={styles.signature}>
   <Text style={styles.signaturee}>Signature</Text>
</View>
<View>
  <Text style={styles.lines}>-------------------------------------------------------------------------------------------------------------------------------------</Text>
</View>
<View style={styles.titlecontent}>
<InvoiceTitle title='Receipt'/>
</View>

<View style={styles.serial}>
<Text style={styles.seria} >Serial No. - {user.serial_no}</Text>
</View>


<View style={styles.table}>

<View style={styles.tableRow}> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>Name</Text> 
  </View> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>{user.student_name}</Text> 
  </View>   
</View>

<View style={styles.tableRow}> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>Date of Registration</Text> 
  </View> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>{(new Date(user.AddedOnDate)).toLocaleDateString()}</Text> 
  </View> 
 </View>

<View style={styles.tableRow}> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>Training/Project/Course</Text> 
  </View> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>{user.current_course}</Text> 
  </View>   
</View>


<View style={styles.tableRow}> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>Fees Details</Text> 
  </View> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>{user.total_fees}</Text> 
  </View>   
</View>

<View style={styles.tableRow}> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>Payment</Text> 
  </View> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>{user.payment}</Text> 
  </View>   
</View>

<View style={styles.tableRow}> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>Due</Text> 
  </View> 
  <View style={styles.tableCol}> 
    <Text style={styles.tableCell}>{user.payment_due}</Text> 
  </View>   
</View>

</View>

<View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Note :- Fees once paid will not be refunded !</Text>
    </View>
   <View style={styles.signature}>
   <Text style={styles.signaturee}>Signature</Text>
</View>

</View>

)}
