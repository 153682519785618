import React, { useContext } from 'react'
import './css/TestDetails.css';
import Sidebar from './Sidebar';
import toggling from './js/toggle.js';
import { AuthContext } from '../../context/authContext';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function TestDetails(props) {
    function Hovering() {
        var element = document.getElementById("user_test_history");
        element.classList.add("hovered");
    }

    const { currentUser } = useContext(AuthContext);
    let student_email = currentUser?.student_email;

    const { test_number } = useParams("");
    const { course_name } = useParams("");

    const [user, setData] = useState([]);
    const [viewgraph, setViewgraph] = useState([]);

    const testDetails = async () => {
        const res = await fetch(`/test/viewtestDetails/${test_number}/${course_name}/${student_email}`);
        const data = await res.json();
        // console.log(data);

        if (res.status === 422 || !data) {
            // console.log("error");
        }
        else {
            setData(data[0])
            // console.log("get data");
        }

        const ress = await fetch(`/test/allTestt/${test_number}/${course_name}/${student_email}`);
        const dataa = await ress.json();
        // console.log(dataa);

        if (ress.status === 422 || !dataa) {
            // console.log("error");
        }
        else {
            setViewgraph(dataa[0])
            // console.log("get data");
        }
    }
    
    useEffect(() => {
        testDetails();
    }, []);

let tquestion = viewgraph.t_question;
let tmarks = viewgraph.total_marks;
let wrongAnswer = tquestion - tmarks;

    return (
        <div className="container-fluid gx-0">
            <Sidebar />
            <div className="main" onLoad={Hovering}>

                <div className="topbar" onLoad={toggling}>

                    <div className="toggle" onClick={toggling}>
                        <i className="fa-solid fa-bars"></i>
                    </div>

                    <div className="welcome">
                        <h3 style={{ fontWeight: "600" }}>{props.PageName}</h3>
                    </div>

                    <div className="user">
                        <img src={require('../../images/profile.jpeg')} alt="userimage" />
                    </div>

                </div>

                <div className="row testDetailsContainer">

                    <div className="testDetailsBlock">
                        <h4>Test Result</h4>
                        <hr />
                        <div className="examInformationBlock px-3">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col"><span>Course Name:</span></div>
                                        <div className='col'><span>{user.course_name}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Test No.:</span></div>
                                        <div className='col'><span>{user.test_number}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Test Date:</span></div>
                                        <div className='col'><span>{(new Date(user.test_date)).toLocaleDateString()}</span></div>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col"><span>Test Duration:</span></div>
                                        <div className='col'><span> {user.duration}</span></div>
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-lg-4 box">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="50"></circle>
                                            <circle className='timeCircle' cx="70" cy="70" r="50"></circle>
                                        </svg>
                                        <div className="number">
                                            <h2>{viewgraph.duration}<span>Mins</span></h2>
                                        </div>
                                    </div>
                                    <span className='text'>Test Submition Time</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col box">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="50"></circle>
                                            <circle className='totalQuestionCircle' cx="70" cy="70" r="50"></circle>
                                        </svg>
                                        <div className="number">
                                            <h2>{viewgraph.t_question}</h2>
                                        </div>
                                           
                                            </div>
                                    <span className='text'>Total Questions</span>
                                </div>
                                <div className="col box">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="50"></circle>
                                            <circle className='correctAnswerCircle' cx="70" cy="70" r="50"></circle>
                                        </svg>
                                        <div className="number">
                                            <h2>{viewgraph.total_marks}</h2>
                                        </div>
                                    </div>
                                    <span className='text'>Correct Answers</span>
                                </div>
                                <div className="col box">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="50"></circle>
                                            <circle className='wrongAnswerCircle' cx="70" cy="70" r="50"></circle>
                                        </svg>
                                        <div className="number">
                                            <h2>{wrongAnswer}</h2>
                                        </div>
                                    </div>
                                    <span className='text'>Wrong Answers</span>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
